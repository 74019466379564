@import url("markdown.css");
@import url("ol.css");
@tailwind base;
@tailwind components;
@tailwind utilities;


#root {
    height: 100vh;
    display: flex;
}

input[type="range"]::-moz-range-thumb, input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1rem;
    height: 1rem;
    background: #FFFFFF;
    cursor: pointer;
    border-radius: 1rem;
}

.icon-fill {
    font-variation-settings: 'FILL' 1
}
